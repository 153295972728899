import { useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Filter, Section, Toggle, Slider, Range, setContext } from '../../components/filter.js';
import FlagContext from '../../components/context.js';
import './styles/search.css';

const RESULTS_PER_LOAD = 6

function Result(props) {
    const external = {
        display: props.external ? "inline" : "none"
    }

    function redirect() {
        console.log('this was pressed', props.link)

        Window.location = props.link
    }

    return (
        <div className='result-container'>
            <a href={props.link} className="result-title">{props.title}</a>
            <div className="result-type">{props.type}</div>
            <div className="result-external" style={external}>External</div> 
            <h1 className="result-subheader">{props.subheader}</h1>
            <p className="result-desc">{props.abstract ?? props.description}</p>
        </div>
    )
}

function App(props) {
    // hooks 
    const navigate = useNavigate()
    const location = useLocation()

    const [urlParams, setParams] = useSearchParams()
    const [flags, setFlags] = useState(location.state.flags ?? {})
    const [data, setData] = useState(null)

    const query = urlParams.get("query")
    
    let offset = 0;

    function search(event) {
        if (event.code != "Enter" && event.type != "click") {
            return
        }

        const searchBar = document.getElementById("search-bar")
        const query = searchBar.value

        if (!query) {
            return navigate('/research/search', {
                state: {
                    flags: flags
                }
            })

            setData(null)
        }

        navigate(`/research/search?query=${query}`, {
            state: {
                flags: flags
            }
        })

        setData(null)
    }

    if (!data) {
        fetch(`/api/search`, {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                query: query,
                offset: offset,
                count: 8,
                filter: flags
            })
        })
        .then(response => response.json())
        .then(response => {
            console.log("the server responded", response)

            setData(response)
        })

        return (
            <p>loading...</p>
        )
    }

    let render = data.map(result => {
        return (
            <Result
                title={result.title}
                subheader={result.subheader}
                type={result.type}
                description={result.preview}
                external={result.external}
                link={result.link}
            />
        )
    })

    return (
        <>
        <h1 className="search-query">Displaying results for "{query}"</h1>

        <input className="search-bar" style={{top: "30px"}} id="search-bar" type="text" placeholder='search..' onKeyDown={search}></input>
        <button className="search-button" style={{top: "35px"}} onClick={search}>Search</button>

        <a href="/research" className="back-button">back</a>

        <Filter>
            <FlagContext.Provider value={{flags: flags, setFlags: setFlags}}>
                <Section title="Attacks" height="210px">
                        <Toggle title="Data Poisoning" category="attack" flag="data poisoning"/>
                        <Toggle title="Evasion Attack" category="attack" flag="evasion"/>
                        <Toggle title="Byzantine" category="attack" flag="byzantine"/>
                        <Toggle title="Model Extraction" category="attack" flag="model extraction"/>
                        <Toggle title="Exploratory Attack" category="attack" flag="exploratory"/>
                        <Toggle title="Defensive distillation" category="attack" flag="distillation"/>
                        <Toggle title="Membership Inference" category="attack" flag="membership inference"/>
                    </Section>
                    <Section title="Company" height="210px" collapsed={false}>
                        <Toggle title="Google" category="compnay"/>
                        <Toggle title="OpenAI" category="company"/>
                        <Toggle title="Nvidia" category="company"/>
                        <Toggle title="Meta" category="company"/>
                        <Toggle title="Mircosoft" category="company"/>
                        <Toggle title="Amazon" category="company"/>
                        <Toggle title="IBM" category="company"/>
                    </Section>
                    <Section title="Country" height="210px" collapsed={false}>
                        <Toggle title="United States" category="country"/>
                        <Toggle title="China" category="country"/>
                        <Toggle title="Canada" category="country"/>
                        <Toggle title="Germany" category="country"/>
                        <Toggle title="Japan" category="country"/>
                        <Toggle title="Russia" category="country"/>
                        <Toggle title="United Kingdom" category="country"/>
                    </Section>
                    <Section title="Institution" height="235px" collapsed={true}>
                        <Toggle title="MIT" category="edu"/>
                        <Toggle title="Stanford" category="edu"/>
                        <Toggle title="University of Cambridge" category="edu"/>
                        <Toggle title="CalTech" category="edu"/>
                        <Toggle title="Harvard" category="edu"/>
                        <Toggle title="University of Tokyo" category="edu"/>
                        <Toggle title="Tsingua University" category="edu"/>
                        <Toggle title="Other" category="edu" flag="other"/>
                    </Section>
                    <Section title="Filetype" height="135px" collapsed={true}>
                        <Toggle title="PDF" category="filetype"/>
                        <Toggle title="DOCX" category="filetype"/>
                        <Toggle title="PPTX" category="filetype"/>
                        <Toggle title="Link" category="filetype"/>
                    </Section>
            </FlagContext.Provider>
        </Filter>

        <div className="search-container">
            {render}
        </div>

        </>
        
    )
}

export default App;