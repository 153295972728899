import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filter, Section, Toggle, Slider, Range, setContext } from '../../components/filter.js';
import FlagContext from '../../components/context.js';
import Topbar from '../../components/topbar.js';
import icon from './logo512.png';
import './styles/research.css';

/* 
docs for me to look at

<Toggle 
    title="Checked"
    flag="other check"
    default={true}
    flags={flags} 
    setFlags={setFlags}
/>
<Slider 
    title="slider" 
    flag="slider" 
    flags={flags} 
    setFlags={setFlags}
    min={-100}
    max={100}
    default={50}
    decimalpoint={1}
/>
<Range 
    title="range" 
    flag="range" 
    flags={flags} 
    setFlags={setFlags}
    min={-100}
    max={100}
    min_default={-50}
    max_default={50}
    decimalpoint={0}
/> 

*/

function AttackContainer(props) {
    function handleClick() {
        window.location = `/research/attack?id=${props.data.id}`
    }

    return (
        <div className="research-attack-container" onClick={handleClick}>
            <img className="research-attack-icon" src={`/cdn/public/${props.data.icon}`}/>
            <h1 className="research-attack-title">
                {props.data.name}
            </h1>
            <p className="research-attack-desc">
                {props.data.description}
            </p>
        </div>
    )
}

function App(props) {
    const navigate = useNavigate()

    const [flags, setFlags] = useState({})
    const [live, setLive] = useState(null)
    const [theoretical, setTheoretical] = useState(null)

    function printFlags() {
        console.log(flags)
    }

    function previewSearch() {

    }

    function search(event) {
        if (event.code != "Enter" && event.type != "click") {
            return
        }

        const searchBar = document.getElementById("search-bar")
        const query = searchBar.value

        if (!query) {
            return navigate('/research/search', {
                state: {
                    flags: flags
                }
            })
        }

        navigate(`/research/search?query=${query}`, {
            state: {
                flags: flags
            }
        })
    }

    useEffect(() => {
        fetch("/api/fetchAttacks?type=live")
        .then(response => response.json())
        .then(response => {
            setLive(response)
        })
        .catch(err => console.log("An error occured while fetching live data:", err))

        fetch("/api/fetchAttacks?type=theoretical")
        .then(response => response.json())
        .then(response => {
            setTheoretical(response)
        })
        .catch(err => console.log("An error occured while fetching theoretical data:", err))
    }, [])

    if (live === null || theoretical === null) {
        return (
            <>loading</>
        )
    }

    const liveRender = live.map(data => {
        return <AttackContainer data={data}/>
    })

    const theoreticalRender = theoretical.map(data => {
        return <AttackContainer data={data}/>
    })

    return (
        <>
            <Topbar name="Research" color="#1C1C1C"/>

            {/* <h1 className="research-title">Research</h1> */}

            <input className="search-bar" id="search-bar" type="text" placeholder='search..' onKeyDown={search}></input>
            <button className="search-button" onClick={search}>Search</button>
            
            <Filter>
                <FlagContext.Provider value={{ flags: flags, setFlags: setFlags }}>
                    <Section title="Attacks" height="210px">
                        <Toggle title="Data Poisoning" category="attack" flag="data poisoning"/>
                        <Toggle title="Evasion Attack" category="attack" flag="evasion"/>
                        <Toggle title="Byzantine" category="attack" flag="byzantine"/>
                        <Toggle title="Model Extraction" category="attack" flag="model extraction"/>
                        <Toggle title="Exploratory Attack" category="attack" flag="exploratory"/>
                        <Toggle title="Defensive distillation" category="attack" flag="distillation"/>
                        <Toggle title="Membership Inference" category="attack" flag="membership inference"/>
                    </Section>
                    <Section title="Company" height="210px" collapsed={false}>
                        <Toggle title="Google" category="compnay"/>
                        <Toggle title="OpenAI" category="company"/>
                        <Toggle title="Nvidia" category="company"/>
                        <Toggle title="Meta" category="company"/>
                        <Toggle title="Mircosoft" category="company"/>
                        <Toggle title="Amazon" category="company"/>
                        <Toggle title="IBM" category="company"/>
                    </Section>
                    <Section title="Country" height="210px" collapsed={false}>
                        <Toggle title="United States" category="country"/>
                        <Toggle title="China" category="country"/>
                        <Toggle title="Canada" category="country"/>
                        <Toggle title="Germany" category="country"/>
                        <Toggle title="Japan" category="country"/>
                        <Toggle title="Russia" category="country"/>
                        <Toggle title="United Kingdom" category="country"/>
                    </Section>
                    <Section title="Institution" height="235px" collapsed={true}>
                        <Toggle title="MIT" category="edu"/>
                        <Toggle title="Stanford" category="edu"/>
                        <Toggle title="University of Cambridge" category="edu"/>
                        <Toggle title="CalTech" category="edu"/>
                        <Toggle title="Harvard" category="edu"/>
                        <Toggle title="University of Tokyo" category="edu"/>
                        <Toggle title="Tsingua University" category="edu"/>
                        <Toggle title="Other" category="edu" flag="other"/>
                    </Section>
                    <Section title="Filetype" height="135px">
                        <Toggle title="PDF" category="filetype"/>
                        <Toggle title="DOCX" category="filetype"/>
                        <Toggle title="PPTX" category="filetype"/>
                        <Toggle title="Link" category="filetype"/>
                    </Section>
                </FlagContext.Provider>
            </Filter>

            <div className="research-attacks-section live">
                <h1 className="research-attacks-title">Live</h1>
                <div className="research-flexbox">
                    {liveRender}
                </div>
            </div>

            <div className="research-attacks-section theoretical">
                <h1 className="research-attacks-title">Theoretical</h1>

                <div className="research-flexbox">
                    {theoreticalRender}
                </div>
            </div>
        </>
    );
}

export default App;
