import { useState, useEffect, useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import Topbar from "../../components/topbar.js";
import "../../styles/index.css";


// basic components

// init function for this page
function App() {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = async (container) => {
        console.log(container);
    };

    const options = useMemo(
        () => ({
            background: {
                color: {
                    value: "#1C1C1C",
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {},
                modes: {},
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: false,
                    speed: 0.8,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 120,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 5 },
                },
            },
            detectRetina: true,
        }),
        []
    );

    if (init) {
        return (
            <>
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={options}
                />
                <Topbar />

                <h1 className="main-title">Machine Learning Threats</h1>
                <div className="description-block">
                    <p className="description">
                        Machine learning attacks involve deliberate attempts to
                        manipulate or exploit vulnerabilities within machine
                        learning (ML) models, compromising their integrity,
                        confidentiality, and reliability. These attacks target
                        different stages of the ML lifecycle, from data
                        collection and training to deployment and operation.
                        Adversarial attacks are a common form where attackers
                        craft inputs that appear normal to humans but cause the
                        model to make incorrect predictions. These inputs, known
                        as adversarial examples, subtly alter the data in ways
                        that deceive the model, which can be particularly
                        dangerous in applications such as image recognition and
                        autonomous vehicles.
                    </p>
                    <button
                        className="description-button"
                        onClick={() => (window.location = "/research")}
                    >
                        See Research
                    </button>
                </div>
            </>
        );
    }

    return (
        <>
            <Topbar />
        </>
    );
}

// export function so it can be used when imported
export default App;
