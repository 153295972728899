/**
 * Button component
 * @param {object} props
 * @returns JSX
 */
function Button(props) {
    return (
        <button
            className="top-bar-button"
            onClick={() => (window.location = props.href)}
        >
            <p>{props.title}</p>
        </button>
    );
}

/**
 * Topbar component
 * @returns JSX
 */
export default function Topbar(props) {
    return (
        <>
            <div className="top-bar" style={{"background-color": props.color ?? "#181818df"}}>
                <h1 className="top-bar-title">{props.name ?? "NCSC ML Catalog"}</h1>

                <div className="button-container">
                    <Button title="Home" href="/" />
                    <Button title="Research" href="/research" />
                    <Button title="Gallery" href="/gallery"/>
                    <Button title="Data" href="/data" />
                </div>
            </div>
        </>
    );
}
