import React, { useState, useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import "../../styles/data.css";
import Topbar from "../../components/topbar.js";

Chart.register(...registerables);

function App() {
    const [init, setInit] = useState(false);
    const [data, setData] = useState([]);
    const chartRefPie = useRef(null);
    const chartRefBar = useRef(null);
    const chartRefScatter = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/api/data");
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                setData(jsonData);
                console.log("Fetched data:", jsonData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setInit(true);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const createPieChart = () => {
            const ctx = document.getElementById("pieChart");
            if (!ctx) {
                return;
            }

            const paperCounts = countPapersByContinent(data);

            if (chartRefPie.current) {
                chartRefPie.current.destroy();
            }

            chartRefPie.current = new Chart(ctx, {
                type: "pie",
                data: {
                    labels: Object.keys(paperCounts),
                    datasets: [
                        {
                            label: "Papers by Continent",
                            data: Object.values(paperCounts),
                            backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56",
                                "#4BC0C0",
                                "#9966FF",
                                "#FF9966",
                            ],
                            hoverOffset: 4,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                },
            });
        };

        const createBarChart = () => {
            const ctx = document.getElementById("barChart");
            if (!ctx) {
                return;
            }

            const topicCounts = countPapersByTopic(data);

            ctx.style.height = "1700px";

            if (chartRefBar.current) {
                chartRefBar.current.destroy();
            }

            chartRefBar.current = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: Object.keys(topicCounts),
                    datasets: [
                        {
                            label: "Papers by Topic",
                            data: Object.values(topicCounts),
                            backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56",
                                "#4BC0C0",
                                "#9966FF",
                                "#FF9966",
                            ],
                            hoverOffset: 4,
                        },
                    ],
                },
                options: {
                    indexAxis: "y",
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            grid: {
                                color: "grey",
                            },
                            title: {
                                display: true,
                                text: "Number of Papers",
                            },
                        },
                        y: {
                            grid: {
                                color: "grey",
                            },
                            title: {
                                display: true,
                                text: "Topics",
                            },
                        },
                    },
                },
            });
        };

        const createScatterChart = () => {
            const ctx = document.getElementById("scatterChart");
            if (!ctx) {
                console.error("Cannot find canvas element #scatterChart");
                return;
            }

            const yearData = countPapersByYear(data);

            if (chartRefScatter.current) {
                chartRefScatter.current.destroy();
            }

            chartRefScatter.current = new Chart(ctx, {
                type: "scatter",
                data: {
                    datasets: [
                        {
                            label: "Papers Published per Year",
                            data: yearData.years.map((year, index) => ({
                                x: parseInt(year, 10),
                                y: yearData.counts[index],
                            })),
                            borderColor: "#36A2EB",
                            backgroundColor: "#36A2EB",
                            showLine: true,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            type: "linear",
                            position: "bottom",
                            min: 2014,
                            max: 2024,
                            grid: {
                                color: "white",
                            },
                            ticks: {
                                stepSize: 1,
                                callback: function (value) {
                                    return new Intl.NumberFormat("en-US", {
                                        useGrouping: false,
                                    }).format(value);
                                },
                            },
                            title: {
                                display: true,
                                text: "Year",
                            },
                        },
                        y: {
                            beginAtZero: true,
                            grid: {
                                color: "white",
                            },
                            ticks: {
                                stepSize: 1,
                            },
                            title: {
                                display: true,
                                text: "Number of Papers",
                            },
                        },
                    },
                    interaction: {
                        mode: "nearest",
                        axis: "x",
                        intersect: false,
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    let label = context.dataset.label || "";
                                    if (label) {
                                        label += ": ";
                                    }
                                    label += `(${context.raw.x}, ${context.raw.y})`;
                                    return label;
                                },
                            },
                        },
                    },
                },
            });
        };

        if (init && data.length > 0) {
            createPieChart();
            createBarChart();
            createScatterChart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, init]);

    const countPapersByContinent = (papers) => {
        const counts = {};
        papers.forEach((paper) => {
            const continent = paper.location;
            if (continent) {
                counts[continent] = counts[continent]
                    ? counts[continent] + 1
                    : 1;
            }
        });
        return counts;
    };

    const countPapersByTopic = (papers) => {
        const counts = {};
        papers.forEach((paper) => {
            const topics = paper.topics;
            if (topics && topics.length > 0) {
                topics.forEach((topic) => {
                    if (counts[topic]) {
                        counts[topic]++;
                    } else {
                        counts[topic] = 1;
                    }
                });
            }
        });
        return counts;
    };

    const countPapersByYear = (papers) => {
        const counts = {};

        papers.forEach((paper) => {
            let dateStr = paper.publish_date || paper.publication_date;
            if (dateStr) {
                const year = extractYear(dateStr);
                if (year >= 2014 && year <= 2024) {
                    counts[year] = counts[year] ? counts[year] + 1 : 1;
                }
            }
        });

        return {
            years: Object.keys(counts).sort((a, b) => a - b),
            counts: Object.keys(counts)
                .sort((a, b) => a - b)
                .map((year) => counts[year]),
        };
    };

    const extractYear = (dateStr) => {
        const date = new Date(dateStr);
        if (!isNaN(date)) {
            return date.getFullYear();
        }

        const yearMatch = dateStr.match(/(\d{4})/);
        return yearMatch ? parseInt(yearMatch[0], 10) : null;
    };

    return (
        <div>
            <Topbar name="Data Visualization" color="#1C1C1C"/>
            <div className="data-content-container">
                <div className="data-chart-wrapper" id="pie">
                    <h2 className="data-chart-heading">
                        Distribution of Research Globally
                    </h2>
                    <div className="data-chart-container">
                        <canvas id="pieChart"></canvas>
                    </div>
                    <div className="data-chart-description">
                        <p>
                            The pie chart provides a visual representation of
                            the global distribution of research across different
                            regions or categories. Each section of the graph
                            corresponds to a specific region or continent, with
                            the size of the section proportional to the amount
                            of research originating from that area. This
                            visualization helps in understanding the
                            geographical spread and concentration of research
                            efforts worldwide. By examining the chart, one can
                            easily identify which regions are contributing more
                            significantly to the various topics within the
                            research landscape of ML and supply chain as a
                            whole.
                        </p>
                    </div>
                </div>
                <div className="data-chart-wrapper" id="bar">
                    <h2 className="data-chart-heading">
                        Distribution of Research over Topics
                    </h2>
                    <div className="data-chart-container">
                        <canvas id="barChart"></canvas>
                    </div>
                    <div className="data-chart-description">
                        <p>
                            The bar chart illustrates the distribution of ML and
                            Supply Chain research over various sub-topics,
                            providing insights into the focus areas within the
                            research community. Each bar represents a specific
                            topic, with its height corresponding to the number
                            of papers published on that topic. This
                            visualization allows us to compare the popularity or
                            research activity across different topics at a
                            glance. By focusing on the overall trends rather
                            than individual topics, we present a single label
                            "Topics" on the x-axis to emphasize the collective
                            categories. This chart is particularly useful for
                            identifying trends, emerging fields, and gaps in ML
                            and Supply Chain research that may require more
                            attention or resources.
                        </p>
                    </div>
                </div>
                <div className="data-chart-wrapper" id="scatter">
                    <h2 className="data-chart-heading">
                        Frequency of Research from 2014 to 2024
                    </h2>
                    <div className="data-chart-container">
                        <canvas id="scatterChart"></canvas>
                    </div>
                    <div className="data-chart-description">
                        <p>
                            The scatter chart depicts the frequency of ML and
                            Supply Chain research publications over a span of
                            ten years, from 2014 to 2024. Each point on the
                            chart represents the number of papers published in a
                            given year, plotted against the respective year on
                            the x-axis. The chart also includes a line
                            connecting these points to highlight the trend over
                            time. This visualization helps in analyzing the
                            temporal evolution of ML and Supply Chain research
                            activity, identifying peaks and troughs, and
                            understanding how research output has changed over
                            the years. Such insights are valuable for us!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
