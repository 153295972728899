import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './styles/attack.css'

const ACCENT_COLOR = "#00FFC2"
const THREAT_COLORS = {
    "Low": "#0AF23D",
    "Medium": "#F2E90A",
    "High": "#F20A0A",
    "Critical": "#930606"
}

function Attribute(props) {
    const nameStyle = {
        color: props.nameColor ?? "#FFFFFF" 
    }

    const valueStyle = {
        color: props.valueColor ?? "#FFFFFF"
    }

    return (
        <div className="attacks-attribute">
            <p className="attacks-attribute-name" style={nameStyle}>{props.name}:</p>
            <p className="attacks-attribute-value" style={valueStyle}>{props.value}</p>
        </div>
    )
}

function Container(props) {
    return (
        <div className={`attacks-container ${props.flag}`}>
            <p className="attacks-container-title">{props.title}</p>

            {props.children}
        </div>
    )
}

function Column(props) {
    const style = {
        width: props.width
    }

    return (
        <div className="column" style={style}>
            <div className="flex-column">
                {props.children}
            </div>
        </div>
    )
}

function Result(props) {
    const external = {
        display: props.external ? "inline" : "none"
    }

    function redirect() {
        console.log('this was pressed', props.link)

        Window.location = props.link
    }

    return (
        <div className='result-container'>
            <a href={props.link} className="result-title">{props.title}</a>
            <div className="result-type">{props.type}</div>
            <div className="result-external" style={external}>External</div> 
            <h1 className="result-subheader">{props.subheader}</h1>
            <p className="result-desc">{props.abstract ?? props.description}</p>
        </div>
    )
}

function App(props) {
    const [urlParams, setParams] = useSearchParams()
    const [data, setData] = useState(null)
    const [research, setResearch] = useState(null)

    const attackId = urlParams.get("id")

    // fetch data from express server 

    useEffect(() => {
        document.body.classList.add("attacks-body")
        
        fetch(`/api/fetchAttack?id=${attackId}`)
        .then(response => response.json())
        .then(data => {
            setData(data)

            console.log(data.attack_type.toLowerCase())

            fetch(`/api/search`, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    filter: {
                        attack: [data.attack_type.toLowerCase()]
                    }, 
                    count: 3
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log("got research", data)
                setResearch(data)
            })
        })
        .catch(err => {
            console.error("An error as occured: ", err)
        })
    }, [])

    if (!data || !research) {
        // return blank page or loading screeN?
        return (<></>)
    }
    
    if (!research) {
        return (<></>)
    }

    // rendering overivew text
    const overview = data.overview.split('\n').map(text => {
        if (!text) {
            return null
        }

        return (
            <>
                <p>{text}</p>
                <div/>
            </>
        )
    })

    const research_render = research.map(result => {
        return (
            <Result
                title={result.title}
                subheader={result.subheader}
                type={result.type}
                description={result.preview}
                external={result.external}
                link={result.link}
            />
        )
    })

    return (
        <>
            <h1 className="attacks-title">{data.name}</h1>
            <h1 className="attacks-subheader">{data.heading}</h1>

            <Column width="20%">
                <Container title="Attributes" flag="left">
                    <Attribute name="Name" value={data.name}/>
                    <Attribute name="Type" value={data.type}/>
                    <Attribute name="Threat" value={data.threat} valueColor={THREAT_COLORS[data.threat]}/>
                    <Attribute name="Class" value={data.class}/>
                    <Attribute name="System" value="Deep Learning"/>
                    <Attribute name="Model" value="Deep Learning"/>
                    <Attribute name="Avaliable Research" value={2348}/>
                </Container>

                <Container title="Tags" flag="left">
                    <p>some stuff here or statistics idk what im going to put here yet</p>
                </Container>
            </Column>

            <Column width="60%">
                <Container title="Overview" flag="middle">
                    {overview}
                </Container>

                <Container title="Research" flag="middle">
                    {research_render}
                </Container>
            </Column>

        </>
    )
}


export default App;