import { useState, useContext, Component as ReactComponent, createRef } from 'react';
import FlagContext from './context.js'
import '../styles/filter.css'


// util
function round(float, point = 0) {
    return Math.floor(float * (10 ** point) + 0.5) / (10 ** point)
}

function clamp(float, min, max) {
    return Math.max(Math.min(float, max), min)
}


export function Toggle(props) {
    // flags
    const {flags, setFlags} = useContext(FlagContext)

    if (!flags[props.category]) {
        console.log('set empty')
        flags[props.category] = []
    }

    const category = flags[props.category]
    const flag = props.flag ?? props.title

    // attributes
    const [value, setValue] = useState(category.includes(flag) ?? props.default ?? false)
    
    if (value && !category.includes(flag)) {
        category.push(flag)
    } else if (!value && category.includes(flag)) {
        const index = category.indexOf(flag)
        
        category.splice(index, 1)
    }

    setFlags(flags)

    // render
    return (
        <div className="toggle-container" onClick={() => setValue(!value)}>
            <div className={value ? "toggle-status toggled" : "toggle-status"}></div>
            <h1 className="toggle-title">{props.title}</h1>
        </div>
    )
}

export function Slider(props) {
    // flag
    const {flags, setFlags} = useContext(FlagContext)

    // attributes
    const [sliding, setSliding] = useState(false)
    const [value, _setValue] = useState(flags[props.flag] ?? props.default ?? 0)

    // element ids
    const sliderId = props.flags + "-slider"

    flags[props.flag] = value
    setFlags(flags)

    // methods
    function setValue(val) {
        val = clamp(val, props.min, props.max)

        _setValue(val)
    }

    function startSlide(event) {
        // safety check
        if (sliding) {
            return
        }

        setSliding(true)

        const slider = document.getElementById(sliderId)
        const rect = slider.getBoundingClientRect()

        const ratio = (event.pageX - rect.x) / rect.width;
        const newValue = (props.max - props.min) * ratio + props.min

        setValue(newValue)
        
        const listener = (event) => {
            const ratio = clamp((event.pageX - rect.x) / rect.width, 0, 1)
            const newValue = (props.max - props.min) * ratio + props.min

            setValue(newValue)
        }

        let mouseUp; mouseUp = () => {
            document.body.removeEventListener("mousemove", listener)
            document.body.removeEventListener("mouseup", mouseUp)

            setSliding(false)
        }

        document.body.addEventListener("mouseup", mouseUp)
        document.body.addEventListener("mousemove", listener)
    }

    // render

    const x = (value - props.min) / (props.max - props.min) * 100

    const sliderSize     = { width: `${x}%` }
    const handlePosition = { left: `calc(${x}% - 7px)` }

    return (
        <div className="slider-container">
            <h1 className="slider-title">{props.title}</h1>
            <p className="slider-min">{props.min}</p>
            <p className="slider-max">{props.max}</p>
            <div className="slider-bounds" id={sliderId} onMouseDown={(e) => startSlide(e)}>
                <div className="slider-slide" style={sliderSize}></div>
                <div className="slider-handle" style={handlePosition}></div>
            </div>
        </div>
    )
}

export function Range(props) {
    // flag
    const {flags, setFlags} = useContext(FlagContext)

    // attributes
    const [sliding, setSliding] = useState(false)
    const [min, _setMin] = useState(flags[props.flag] ? flags[props.flag].min : props.min_default ?? 0)
    const [max, _setMax] = useState(flags[props.flag] ? flags[props.flag].max : props.max_default ?? 0)

    // element ids
    const sliderId = props.flags + "-slider"

    flags[props.flag] = {
        min: min,
        max: max,
    }
    setFlags(flags)

    // methods
    function setMin(val) {
        val = clamp(val, props.min, Math.min(props.max, max))
        val = round(val, props.decimalpoint)

        _setMin(val)
    }

    function setMax(val) {
        val = clamp(val, Math.max(props.min, min), props.max)
        val = round(val, props.decimalpoint)

        _setMax(val)
    }

    function startSlide(event, type) {
        if (sliding) { 
            return
        }

        setSliding(true)

        const slider = document.getElementById(sliderId)
        const rect = slider.getBoundingClientRect()

        const ratio = (event.pageX - rect.x) / rect.width
        const newValue = (props.max - props.min) * ratio + props.min

        if (type == "min") {
            setMin(newValue)
        } else if (type == "max") {
            setMax(newValue)
        }

        const listener = (event) => {
            const ratio = clamp((event.pageX - rect.x) / rect.width, 0, 1)
            const newValue = (props.max - props.min) * ratio + props.min

            if (type == "min") {
                setMin(newValue)
            } else if (type == "max") {
                setMax(newValue)
            }
        }

        let mouseUp; mouseUp = () => {
            document.body.removeEventListener("mouseup", mouseUp)
            document.body.removeEventListener("mousemove", listener)

            setSliding(false)
        }

        document.body.addEventListener("mouseup", mouseUp)
        document.body.addEventListener("mousemove", listener)
    }

    // render

    const minPercentage = (min - props.min) / (props.max - props.min) * 100
    const maxPercentage = (max - props.min) / (props.max - props.min) * 100

    const sliderStyle = {
        left: `${minPercentage}%`,
        width: `${maxPercentage - minPercentage}%`
    };
    const minHandle = {
        left: `calc(${minPercentage}% - 7px)`
    };
    const maxHandle = {
        left: `calc(${maxPercentage}% - 7px)`
    };

    return (
        <div className="slider-container">
            <h1 className="slider-title">{props.title}</h1>
            <p className="slider-min">{props.min}</p>
            <p className="slider-max">{props.max}</p>
            <div className="slider-bounds" id={sliderId}>
                <div className="slider-slide" style={sliderStyle}></div>
                <div className="slider-handle" style={minHandle} onMouseDown={(e) => startSlide(e, 'min')}></div>
                <div className="slider-handle" style={maxHandle} onMouseDown={(e) => startSlide(e, 'max')}></div>
            </div>
        </div>
    )
}

export function Section(props) {
    // attributes
    const [ collapsed, setCollapsed ] = useState(props.collapsed ?? false)

    // render

    const section_height = collapsed ? "30px" : props.height

    return (
        <div className="section-container" style={{height: section_height}}>
            <h1 className="section-title">{props.title}</h1>
            <div className="section-collapse-button" onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? '+' : '-'}
            </div>

            {props.children}
            {/* <div className="divider"></div> */}
        </div>
    )
}

export function Filter(props) {
    return (
        <div className="filter-container">
            <h1 className="filter-title">Filter</h1>

            {props.children}
        </div>
    )
}