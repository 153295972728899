import Topbar from '../../components/topbar.js';
import "./gallery.css"
import image1 from "./Cyber NCSC.png"
import image2 from "./Cyber2.png"
import image3 from "./cyber crime.png"
import image4 from "./Supply2.png"
import image5 from "./datapois.png"
import image6 from "./envasion.png"
function App () {
    return (
        <body>
            <Topbar name="Gallery" color="#1C1C1C"/>

        <header>
            <h1 class="g-title">Gallery</h1>
        </header>
        <section class="gallery-title">
            <h1 class="gallery-h1">Cyber Security Threats & Supply Chain Integrity</h1>
            <h2 class="gallery-h2">Exploring the intersection of cyber threats and supply chain resilience</h2>
            <div class="text-content">
                <p class="gallery-p">Cybersecurity threats pose a significant risk to supply chain integrity, as vulnerabilities in one part of the supply chain can compromise the entire network. Attackers may exploit weaknesses in software, hardware, or communication channels to gain unauthorized access, disrupt operations, or manipulate data. Ensuring robust cybersecurity measures, such as regular audits, encryption, and multi-factor authentication, is crucial for protecting sensitive information and maintaining the reliability of supply chains. By addressing these threats proactively, organizations can safeguard their assets and enhance their resilience against potential disruptions.
                </p>
            </div>
        </section>
        <main class="gallery-container">
            <div class="gallery-item">
                <img class="gallery-image" src={image1}alt="Design 1"/>
                <div class="gallery-details">
                    <h3 class="gallery-title">Global Cybersecurity Threat Landscape: Key Adversaries to the United States</h3>
                    <p class="gallery-description">The image is a world map highlighting different countries with varying levels of cybersecurity threats to The United States of America. The text "Foreign Cybersecurity Threats: United States" is displayed at the top of the image, with a warning icon to emphasize the seriousness of the threats. This map uses color-coding to indicate threat levels: Countries in red pose the highest cybersecurity threats to the United States and they include: Russia, China, North Korea, and Iran. Countries in yellow pose a medium level threat of cybersecurity. These regions include parts of Europe and parts of Asia, that extends from Eastern Europe to the Middle East. Meanwhile, countries in green pose a low level threat of cybersecurity to America. These regions include Australia and areas surrounding in Oceania and regions majority in Africa. And lastly, the legend on the right side of the map explains the color-coding for the different threat levels.</p>
                </div>
            </div>
            <div class="gallery-item">
                <img class="gallery-image" src={image2} alt="Design 2"/>
                <div class="gallery-details">
                    <h3 class="gallery-title">The Ongoing Cyber Siege: America's Digital Vulnerability</h3>
                    <p class="gallery-description">This image implies the severe threat that cyberattacks pose to the national security in the United States. Hackers are actively targeting critical infrastructure and systems, potentially causing widespread disruption. As far as the image itself goes, the presence of the hacker in the foreground highlights the anonymity and sophistication of modern cybercriminals. Some other key features include the warning icon and "SYSTEM HACKED!" text emphasize the urgency and potential impact that these attacks cause, suggesting that current defenses might be insufficient and vulnerable to breaches.</p>
                </div>
            </div>
            <div class="gallery-item">
                <img class="gallery-image" src={image3} alt="Design 3"/>
                <div class="gallery-details">
                    <h3 class="gallery-title">The Invisible Threat: Navigating the Complex Landscape of Cybercrime</h3>
                    <p class="gallery-description">This image implies the multifaceted nature of cybercrime. Cybercriminals are well-organized and constantly evolving, using various methods to exploit vulnerabilities. As far as the image goes, the repeating of hooded figures implies that cyber threats are widespread and not limited to a single source. The presence of icons representing different types of cyber threats implies that individuals and organizations must be vigilant against a range of potential attacks, from malware to phishing and beyond. The overall atmosphere conveys a sense of an ongoing and relentless assault on digital security.</p>
                </div>
            </div>
            <div class="gallery-item">
                <img class="gallery-image" src={image4} alt="Design 4"/>
                <div class="gallery-details">
                    <h3 class="gallery-title">Securing the Supply Chain: Navigating Cyber Risks in Interconnected Networks</h3>
                    <p class="gallery-description">This image implies the intricate interconnectivity and varying levels of risk within the supply chain, emphasizing the susceptibility to cyber threats at different stages. It suggests that while some components of the supply chain are secure, others are vulnerable, creating potential entry points for cyber attackers. The flow from secure suppliers and manufacturers to higher-risk retailers highlights how a breach at any stage can compromise the entire chain. The presence of the hacker figure underscores the reality and immediacy of these threats, illustrating that attackers actively target weaknesses in the supply chain. The overall message is a call to vigilance and enhanced security measures across all segments to protect the entire network from cyber threats.</p>
                </div>
            </div>
            
            <div class="gallery-item">
                <img class="gallery-image" src={image5} alt="Design 5"/>
                <div class="gallery-details">
                    <h3 class="gallery-title">Data Poisoning on Machine Learning Models</h3>
                    <p class="gallery-description">The graphic illustrates the comparative effects of data poisoning on two models, Model A (Unaffected) and Model B (Affected). On the left panel, Model A receives clean information and maintaining high performance, with metrics such as 99% accuracy and no error rate, depicted through a stable model structure and correct predictions. In contrast, the right panel shows Model B, which show clean and poison data. This model however exhibits a distorted structure and poor performance, with 60% accuracy and 40% error rate, leading to incorrect predictions. The white lines between each graphic demonstrates the flow of data from a common source split into clean and poisoned subsets entering the models. The bottom section summarizes the key differences, suggesting that Model A's robustness and Model B's vulnerability might be due to differences in their training or architecture, and it briefly touches on potential mitigation techniques for data poisoning. </p>
                </div>
            </div>

            <div class="gallery-item">
                <img class="gallery-image" src={image6} alt="Design 6"/>
                <div class="gallery-details">
                    <h3 class="gallery-title">Mitigating Evasion Attacks on U.S. Cybersecurity Systems</h3>
                    <p class="gallery-description">This graphic highlights the critical threat of evasion attacks on U.S. cybersecurity systems. The upper portion of the image depicts a hacker orchestrating an attack, symbolizing the increasing sophistication and prevalence of cyber threats. The bottom portion of the image shows cybersecurity professionals responding to a breach, emphasizing the importance of preparedness, real-time monitoring, and swift countermeasures. There is an urgent need for robust cybersecurity defenses, continuous vigilance, and proactive strategies to protect national infrastructure from evolving cyber threats. </p>
                </div>
            </div>
        </main>
    </body>
    )
}

export default App