import { createBrowserRouter } from "react-router-dom";

import Root from "./pages/root/index.js";
import Research from "./pages/research/index.js";
import Attack from "./pages/attacks/index.js";
import Search from "./pages/search/index.js";
import Data from "./pages/data/index.js";
import Galley from "./pages/gallery/index.js";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
    },
    {
        path: "/research",
        element: <Research />,
        // children: [
        //     {
        //         path: "/research/search/:query",
        //         element: <Research/>,
        //     }
        // ]
    },
    {
        path: "/research/attack",
        element: <Attack />,
    },
    {
        path: "/research/search",
        element: <Search />,
    },
    {
        path: "/data",
        element: <Data />,
    },
    {
        path: "/gallery",
        element: <Galley />
    }
]);

export default router;
